.header {
  display: flex;
  flex-direction: row;
  font-family: 'Dosis', sans-serif !important;
  justify-content: center;
}
#root {
  margin: auto;
  scrollbar-width: thin !important;
  overflow-x: hidden !important;
  font-family: 'Dosis', sans-serif !important;
  color: black !important;
  overflow-y: hidden !important;
  /* transition: all .3s !important; */
  transition: all 0.3s ease-in !important;
  transition-timing-function: ease-in-out !important;
  -webkit-transition: all .3s !important;
  -moz-transition: all .3s !important;
  -ms-transition: all .3s !important;
  -o-transition: all .3s !important;
}
#root::-webkit-scrollbar,
html::-webkit-scrollbar,
body *::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px !important;
  height: 0px !important;
  background: #001620 !important;
}
#root::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb,
body * ::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #bebebe;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
body * ::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  scrollbar-width: thin !important;
  scroll-behavior: smooth !important;
  box-sizing: border-box !important;
}
html {
  scrollbar-width: thin !important;
  scroll-behavior: smooth !important;
  overflow-x: hidden;
  box-sizing: border-box !important;
  font-family: 'Dosis', sans-serif !important;
  box-shadow: inset !important;
}


body *::-webkit-scrollbar,
body::-webkit-scrollbar {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  scrollbar-width: thin !important;
  scroll-behavior: smooth !important;
  box-sizing: border-box !important;
}
.chakra-modal__content {
  border: 0.5px solid !important;
  box-shadow: rgba(149, 157, 165, 1) 0px 0px 24px !important;
  border-image: linear-gradient(to right, red, purple) !important;
  border-radius: 20px !important;
  margin: auto !important;
  width: 98% !important;
  margin-top: 10px !important;
  max-width: 1200px !important;
}

.chakra-modal__content::after {
  border: 0.5px solid !important;
  border-image: linear-gradient(to right, red, purple) !important;
  border-radius: 20px !important;
}

.chakra-modal__content-container {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.chakra-text {
  font-size: 17px !important;
}
@media (max-width: 1022px) {
  body {
    padding: auto 20px;
  }
}
.contactgetintouchmain {
  padding: 90px 50px 00px 50px;
}
.slick-slider button{
  display: none !important;
}
